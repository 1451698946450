import { useSelector } from "react-redux";

import { Button, Flex, Text, Link as CLink } from "@chakra-ui/react";

// eslint-disable-next-line import/no-unresolved
import { YellowAlertInfo } from "ui-kit/icon";

import { useHistory } from "react-router-dom";

import { ButtonLink, useContainerValue, withContainerBreakpoints } from "ui-kit";

import { MOBILE_NAV_HEIGHT } from "components/layout/constants";
import { getManualRetailerVacationOver, getActiveVacation } from "store/selectors/settings";
import { useQueryFeatureFlag } from "hooks/feature-flags";
import { FEATURE_FLAG_VACATION_MODE } from "constants/feature-flags";
import { getUserData } from "store/selectors/user";

import { formatVacationDate } from "../../shared/helpers";

const BREAKPOINTS = {
  lg: 992
};

const ReactivateBanner = ({ isLoggedIn = false }) => {
  const manualRetailerVacationOver = useSelector(getManualRetailerVacationOver);
  const activeVacation = useSelector(getActiveVacation);
  const history = useHistory();
  const { isFeatureFlagEnabled } = useQueryFeatureFlag(FEATURE_FLAG_VACATION_MODE);
  const user = useSelector(getUserData);
  const hubspotSrc = `${import.meta.env.VITE_HUBSPOT_URL}&firstname=${encodeURIComponent(
    user.first_name
  )}&lastname=${encodeURIComponent(user.last_name)}&email=${encodeURIComponent(
    user.email
  )}&company=${encodeURIComponent(user.retailer?.business_name)}`;

  const ReactivateButton = () => {
    if (!isFeatureFlagEnabled) {
      return (
        <ButtonLink
          as={CLink}
          href={hubspotSrc}
          isExternal
          fontSize="16px"
          borderRadius="4px"
          border="solid 1px #F78F45"
          marginLeft="auto"
          color="black"
          borderColor="#F78F45"
          backgroundColor="#FDE2CE"
          textTransform="none"
          _hover={{ backgroundColor: "#FDE2CE" }}
        >
          Schedule a call to activate
        </ButtonLink>
      );
    }
    return (
      <Button
        onClick={() => {
          history.push("/settings");
        }}
        fontSize="16px"
        borderRadius="4px"
        border="solid 1px #F78F45"
        marginLeft="auto"
        color="black"
        borderColor="#F78F45"
        backgroundColor="#FDE2CE"
        textTransform="none"
        _hover={{ backgroundColor: "#FDE2CE" }}
      >
        View My Settings
      </Button>
    );
  };
  const wrapperStyle = useContainerValue({
    lg: {
      padding: "15px",
      marginTop: 0,
      backgroundColor: "#FDE2CE",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      alignItems: "center"
    },
    default: {
      padding: "15px",
      backgroundColor: "#FDE2CE",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      alignItems: "center"
    }
  });
  const alertBanner = (
    <Flex style={wrapperStyle}>
      <YellowAlertInfo />
      &nbsp;&nbsp;
      <Text fontSize="16px" color="#1E2533">
        {`Your account is currently on vacation until ${formatVacationDate(
          activeVacation?.endDate
        )}. To reactivate and allow new orders, adjust your settings.`}
      </Text>
      <ReactivateButton />
    </Flex>
  );

  const noAlertBumpDiv = (
    <div
      style={useContainerValue({
        lg: { minHeight: 0 },
        default: { minHeight: MOBILE_NAV_HEIGHT }
      })}
    />
  );
  return manualRetailerVacationOver ? alertBanner : noAlertBumpDiv;
};

export default withContainerBreakpoints(ReactivateBanner, BREAKPOINTS);
