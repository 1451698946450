export function getRetailerUrl(retailerId: string) {
  const field = encodeURIComponent(`${import.meta.env.VITE_LISTINGS_SEARCH_INDEX}[refinementList][provider][0]`);
  const value = encodeURIComponent(retailerId);
  return `/search?${field}=${value}`;
}

export function getBrandUrl(brandId: string) {
  const field = encodeURIComponent(`${import.meta.env.VITE_LISTINGS_SEARCH_INDEX}[refinementList][brand][0]`);
  const value = encodeURIComponent(brandId);
  return `/search?${field}=${value}`;
}
