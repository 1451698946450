import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useMount } from "react-use";

import { toCurrency } from "shared/helpers";

import LazyBackgroundImage from "components/shared/lazy-background-image/lazy-background-image";
import { ButtonLink } from "ui-kit";

import { useIsSearchV2Enabled } from "hooks/feature-flags";
import { getBrandUrl } from "components/m-r-indexed-search-v2/utils";

import "components/popup-manager/popup-cart/popup-cart.scss";

const PopupCartWrapper = ({ children }) => {
  const props = {
    className: "popup-cart--beta-layout",
    sx: {
      position: "fixed",
      minH: "360px",
      left: "484px",
      zIndex: "3",
      width: "345px",
      padding: "25px",
      lineHeight: "1.5em",
      color: "black",
      bg: "white",
      border: "1px solid #ededed",
      transform: "translateY(-90%) translateX(-50%)",

      "&::before": {
        position: "fixed",
        right: "100%",
        top: "93%",
        display: "block",
        width: "0",
        height: "0",
        content: "''",
        borderColor: "transparent transparent white transparent",
        borderStyle: "solid",
        borderWidth: "0 10px 20px 10px",
        transform: "translateY(-50%) rotate(-90deg)"
      }
    }
  };

  return <Box {...props}>{children}</Box>;
};

const PopupCart = props => {
  const { data, removePopup } = props;
  const isSearchV2Enabled = useIsSearchV2Enabled();
  const filters =
    document.referrer.includes("search") && document.referrer.split("?").length > 1
      ? document.referrer.split("?")[1]
      : "";

  useMount(() => {
    const timeout = setTimeout(() => {
      removePopup();
    }, 501100);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <PopupCartWrapper>
      <h3 className="popup-cart__header">Added to shopping bag</h3>
      <HStack w="full" className="popup-cart__product">
        <LazyBackgroundImage
          alt={`${data.productvariation?.display_name} preview`}
          className="popup-cart__image"
          src={data.productvariation?.display_image?.image?.thumbnail_big}
        />
        <div className="popup-cart__details">
          <Link
            className="brand-link"
            to={
              isSearchV2Enabled
                ? getBrandUrl(data.productvariation.product.display_brand)
                : `/listings/brand/${data.productvariation.product.brand}`
            }
            onClick={removePopup}
          >
            {data.productvariation.product.display_brand}
          </Link>
          <br />
          <b>{data.productvariation?.display_name}</b>
          <br />
          Units: {data.totalUnits}
          <br />
          {toCurrency(data.totalPrice)}
        </div>
      </HStack>
      <VStack gap="2" pt="4">
        <ButtonLink
          to="/cart"
          onClick={removePopup}
          variant="tertiary"
          size="lg"
          w="full"
          py="4"
          px={{ base: "1", md: "2", lg: "3" }}
          align="center"
          fontSize="md"
          data-testid="popup-cart-go-to-shopping-bag"
        >
          Go To Shopping Bag
        </ButtonLink>
        <ButtonLink
          to={isSearchV2Enabled ? `/search?${filters}` : "/browse"}
          variant="link"
          colorScheme="blue"
          size="md"
          onClick={removePopup}
        >
          Continue Shopping
        </ButtonLink>
      </VStack>
    </PopupCartWrapper>
  );
};

export default PopupCart;
