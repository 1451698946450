import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import flagsApi from "api/flags-api";
import { FEATURE_FLAT_SEARCH_V2 } from "constants/feature-flags";

export const Feature = ({ children }) => {
  const [flag] = useState(false);

  if (flag) {
    return children;
  }

  // TODO: Issue MRP-2770
  // eslint-disable-next-line unicorn/no-null
  return null;
};

export const ApiFeatureFlagged = ({ children, featureName }) => {
  const { isLoading, isError, data } = useQuery(["feature-flags"], () => flagsApi.getFlags());

  if (isLoading || isError) {
    // TODO: Issue MRP-2770
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
  let shouldRender = false;
  for (const flag of data) {
    const { feature_name: name, enabled } = flag;
    if (name === featureName && enabled) {
      shouldRender = true;
      break;
    }
  }
  if (shouldRender) {
    return children;
  }
  // TODO: Issue MRP-2770
  // eslint-disable-next-line unicorn/no-null
  return null;
};

export const useQueryFeatureFlag = flagName => {
  const queryResp = useQuery(["feature-flags"], () => flagsApi.getFlags());
  if (queryResp.data) {
    const featureFlag = queryResp.data.find(flag => flag.feature_name === flagName);
    return {
      ...queryResp,
      isFeatureFlagEnabled: Boolean(featureFlag?.enabled)
    };
  }
  return { ...queryResp, isFeatureFlagEnabled: undefined };
};

export const useIsSearchV2Enabled = () => {
  const { isFeatureFlagEnabled } = useQueryFeatureFlag(FEATURE_FLAT_SEARCH_V2);
  // eslint-disable-next-line no-undef
  const isSearchV2Enabled = isFeatureFlagEnabled && !sessionStorage.getItem("optedOutOfV2Search");
  return isSearchV2Enabled;
};
