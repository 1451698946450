/* eslint-disable unicorn/no-null */
import { ACTION_TYPES } from "constants/action-types";

const initialState = {
  isVacationModeOpen: false,
  status: "Active",
  activeVacation: null,
  futureVacations: [],
  showBackInAction: false,
  vacationDetails: {
    reason: null,
    startDate: null,
    endDate: null,
    isActive: false,
    isManualOverrideRequired: false
  },
  vacationToUpdate: {
    reason: null,
    startDate: null,
    endDate: null,
    isActive: false
  }
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SETTINGS.TOGGLE_VACATION_MODE:
      return { ...state, isVacationModeOpen: action.payload };
    case ACTION_TYPES.SETTINGS.SET_VACATION_STATUS:
      return { ...state, status: action.payload.status };
    case ACTION_TYPES.SETTINGS.SET_ACTIVE_VACATION:
      return { ...state, activeVacation: action.payload };
    case ACTION_TYPES.SETTINGS.SET_FUTURE_VACATIONS:
      return { ...state, futureVacations: action.payload };
    case ACTION_TYPES.SETTINGS.SET_SHOW_BACK_IN_ACTION:
      return { ...state, showBackInAction: action.payload.showBackInAction };
    case ACTION_TYPES.SETTINGS.SET_VACATION_DETAILS:
      return { ...state, vacationDetails: { ...state.vacationDetails, ...action.payload } };
    case ACTION_TYPES.SETTINGS.SET_VACATION_TO_UPDATE:
      return { ...state, vacationToUpdate: { ...state.vacationToUpdate, ...action.payload } };
    default:
      return state;
  }
}
