export function getSettings(state) {
  return state.settings;
}

export function getVacationModeOpen(state) {
  return getSettings(state).isVacationModeOpen;
}

export function getVacationModeStatus(state) {
  return getSettings(state).status;
}

export function getVacationDetails(state) {
  return getSettings(state).vacationDetails;
}

export function getFutureVacations(state) {
  return getSettings(state).futureVacations;
}

export function getActiveVacation(state) {
  return getSettings(state).activeVacation;
}

export function getVacationCompletedInPastDay(state) {
  return getSettings(state).showBackInAction as boolean;
}

export function getManualRetailerVacationOver(state) {
  const now = new Date();
  const {activeVacation} = getSettings(state);
  return activeVacation?.isManualOverrideRequired && now.toISOString() >= activeVacation.endDate;
}

export function getShowBackInAction(state) {
  return getSettings(state).showBackInAction;
}

export function getVacationToUpdate(state) {
  return getSettings(state).vacationToUpdate;
}
