import { PRODUCT_UPLOAD_MAX_RETAIL } from "constants/email";
import { LIGHTSPEED_LOGIN_URL } from "constants/lightspeed";
import { SHOPIFY_FIX_PRODUCT_URL, SHOPIFY_LIST_URL, SHOPIFY_LOGIN_URL } from "constants/shopify";

export const SHOPIFY_SOURCE = "Shopify";
export const SHOPIFY_PUBLIC_SOURCE = "ShopifyPublic";
export const LIGHTSPEED_SOURCE = "Lightspeed";
export const MR_GENERIC_SOURCE = "MR_GENERIC";
export const AUTOMATED_CSV_SOURCE = 'AutomatedCsv';

export type IngestionSource = "Shopify" |"ShopifyPublic" | "Lightspeed" | "MR_GENERIC" | 'AutomatedCsv'
export const INGESTION_SOURCE_DISPLAY = {
  [SHOPIFY_SOURCE]: 'Shopify',
  [SHOPIFY_PUBLIC_SOURCE]: 'Shopify',
  [LIGHTSPEED_SOURCE]: 'Lightspeed',
  [MR_GENERIC_SOURCE]: 'Manual',
  [AUTOMATED_CSV_SOURCE]: 'Automated CSV',
}

export const AUTOMATED_INGESTION_SOURCES = new Set([
  LIGHTSPEED_SOURCE,
  SHOPIFY_SOURCE,
  SHOPIFY_PUBLIC_SOURCE,
  AUTOMATED_CSV_SOURCE,
])

export const AUTOMATED_ECOM_INGESTION_SOURCES = new Set([
  LIGHTSPEED_SOURCE,
  SHOPIFY_SOURCE,
  SHOPIFY_PUBLIC_SOURCE,
])

// Use the generic CR.parsed_data schema
export const GENERIC_PARSED_DATA_SOURCES = new Set([
  LIGHTSPEED_SOURCE,
  AUTOMATED_CSV_SOURCE
])

export const ECOM_LOGIN_URLS = {
  [SHOPIFY_SOURCE]: SHOPIFY_LOGIN_URL,
  [SHOPIFY_PUBLIC_SOURCE]: SHOPIFY_LOGIN_URL,
  [LIGHTSPEED_SOURCE]:LIGHTSPEED_LOGIN_URL,
}

export const SHOPIFY = "SHOPIFY";
export const LIGHTSPEED = "LIGHTSPEED";
export const MR_GENERIC = "MR_GENERIC";

export const APP_CONNECTIONS_TO_SOURCES = {
  /* eslint-disable @typescript-eslint/naming-convention */
  SHOPIFY: [SHOPIFY_SOURCE, SHOPIFY_PUBLIC_SOURCE],
  LIGHTSPEED: [LIGHTSPEED_SOURCE],
  MR_GENERIC: [MR_GENERIC_SOURCE]
  /* eslint-enable @typescript-eslint/naming-convention */
};

export const SOURCE_TO_APP_CONNECTIONS = {
  [SHOPIFY_PUBLIC_SOURCE]: SHOPIFY,
  [SHOPIFY_SOURCE]: SHOPIFY,
  [LIGHTSPEED_SOURCE]: LIGHTSPEED,
  [MR_GENERIC_SOURCE]: MR_GENERIC
};

export const APP_CONNECTIONS_TO_FIX_LINKS = {
  [SHOPIFY]: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    linkHref: SHOPIFY_FIX_PRODUCT_URL,
    linkText: "Fix in Shopify →"
  },
  [LIGHTSPEED]: {
    linkHref: LIGHTSPEED_LOGIN_URL,
    linkText: "Fix in Lightspeed →"
  }
};
export const APP_CONNECTIONS_TO_LINKS = {
  [SHOPIFY]: {
    linkHref: SHOPIFY_LIST_URL
  },
  [LIGHTSPEED]: {
    linkHref: LIGHTSPEED_LOGIN_URL
  },
  [MR_GENERIC]: {
    linkHref: `mailto:${PRODUCT_UPLOAD_MAX_RETAIL}?subject=Inventory error fixes`
  }
};

export const POS_RETAILER_TYPES = [SHOPIFY, LIGHTSPEED];
